import React from "react"
import styled from "styled-components"
import ServiceImage from "../molecules/service-image";

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`

const ServiceImages = ({ data }) => (
  <Wrapper>
      {data.map((usluga) => (
        <ServiceImage data={usluga} />
      ))}
  </Wrapper>
)

export default ServiceImages
