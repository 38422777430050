import React from "react"
import styled from "styled-components"
import ContentCenter from "../../partials/content-center"
import Img from "gatsby-image"
import { Dialog } from "@reach/dialog"
import "@reach/dialog/styles.css"
import Realisations from "../../organisms/realisations"
import Tabs from "../../organisms/benefits-accordion"
import SectionTitle from "../../molecules/section-title";
import Container from "../../partials/container"
import { color } from "../../../components/colors"

const Gallery = styled.section`
padding: 48px 0 0;

@media (min-width: 769px) {
  padding: 104px 0 0;
}
`

const ModalContent = styled.div`
  display: block;
  position: relative;

  .gatsby-image-wrapper {
    width: 100%;
    padding-bottom: 56.4%;

    img {
      background-color: #1c1b1e;
      object-fit: contain !important;
    }
  }
`

const Close = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 43px;
  width: 43px;
  background-color: ${color.secondary};
  border: 0;
  padding: 10px;
  position: absolute;
  top: 1rem;
  right: 1rem;
  transition: background-color 0.3s ease;
  cursor: pointer;
  z-index: 1;

  &::before,
  &::after {
    content: "";
    height: 5px;
    width: 30px;
    background-color: ${color.black};
    position: absolute;
    top: 46%;
    transform-origin: center;
    border-radius: 25px;
    transition: background-color 0.3s ease;
  }

  &::before {
    transform: rotate(45deg);
  }

  &::after {
    transform: rotate(-45deg);
  }

  &:hover {
    background-color: #fff;

    &::before,
    &::after {
      background-color: ${color.black};
    }
  }

  &:focus {
    outline: 0;
  }

  @media (min-width 576px) {
    top: 2rem;
    right: 2rem;
  }
`

class GallerySection extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      showLightbox: false,
      selectedImage: null,
      isDescription: false,
      showDiscription: false,
      imageDiscription: null,
    }
  }

  clickHandler = (e, src) => {
    //e.preventDefault()
    this.setState({
      showLightbox: true,
      selectedImage: src,
    })
  }

  render() {
    const { showLightbox, selectedImage } = this.state
    
    return (
      <Gallery id="realizacje">
        <Container>
          <SectionTitle title="Realizacje" />
        </Container>
        <ContentCenter>
          <Realisations
          clickHandler={this.clickHandler} 
          data={this.props.data.galeria}/>
        </ContentCenter>
        <Container>
          <SectionTitle title="Galeria" />
        </Container>
        <ContentCenter>
          <Tabs 
          clickHandler={this.clickHandler} 
          data={this.props.data.galeria}/>
        </ContentCenter>
        {showLightbox && (
          <Dialog
            onDismiss={() =>
              this.setState({
                showLightbox: false,
                selectedImage: null,
                isDescription: false,
                showDiscription: false,
                imageDiscription: null,
              })
            }
          >
            <ModalContent>
              <Close
                onClick={() =>
                  this.setState({
                    showLightbox: false,
                    selectedImage: null,
                    isDescription: false,
                    showDiscription: false,
                    imageDiscription: null,
                  })
                }
              />
                <Img fixed={selectedImage} />
            </ModalContent>
          </Dialog>
        )}
      </Gallery>
    )
  }
}

export default GallerySection
