import React from "react";
import styled from "styled-components";
import { StaticImage } from "gatsby-plugin-image";
import Container from "../../partials/container";
import { color } from "../../../components/colors"
import SectionTitle from "../../molecules/section-title";
import ServiceImages from "../../organisms/service-images";
import { Parallax, Background } from "react-parallax"
import ContentCenter from "../../partials/content-center";

const Counter = styled.section`
  width: 100%;
  padding: 48px 0 0;

  @media (min-width: 769px) {
    padding: 104px 0 0;
  }

  .react-parallax {
    display: none;

    @media (min-width: 769px) {
      display: block;
      height: 370px;
    }
    
    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.4);
    }

    .gatsby-image-wrapper {
      width: 100%;
    }

    .gatsby-image-wrapper img {
      top: -15%;
      width: 100%;
      object-fit: cover;
    }
  }

  .react-parallax-background-children {
    width: 100%;
  }
`

const MobileBackground  = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);

  .gatsby-image-wrapper {
    height: 100%;
  }

  @media (min-width: 769px) {
    display: none;
  }
`;

const Row = styled.div`
  position: relative;
  width: 100%;
`;

const Content = styled.div`
  display: flex;
  align-items: center;
  top: 0;
  left: 0; 
  right: 0; 
  bottom: 0;
  padding: 48px 0;
  
  @media (min-width: 769px) {
    position: absolute;
    padding: 0;
  }
`;

const Numbers = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 15px;
  color: #fff;
  text-align: center;

  @media (min-width: 769px) {
    width: 50%;
    padding: 0 15px;
  }

  h3 {
    font-size: 56px;
    
    @media (min-width: 769px) {
      font-size: 110px;
    }
  }

  .counter-aria-divider {
    font-size: 0;
    line-height: 0;
  }
  
  .counter-aria-divider::before {
    display: inline-block;
    content: '';
    width: 95px;
    border-top: 1px solid ${color.white};
  }
  
  *+.counter-aria-divider {
    margin-top: 16px;

    @media (min-width: 769px) {
      margin-top: 24px;
    }
  }

  .counter-aria-title {
    display: block;
    text-transform: uppercase;
    letter-spacing: 2px;
    margin-top: 24px;
    font-weight: 700;
    
    @media (min-width: 769px) {
      margin-top: 32px;
    }
  }
`;

const HomepageCounterSection = ({ data }) => (
<Counter>
  <Row>
    <Parallax strength={-150}>
      <Background className="custom-bg">
        <StaticImage src="../../../images/slide-3.jpg" />
      </Background>
    </Parallax>
    <MobileBackground>
      <StaticImage src="../../../images/slide-3.jpg" />
    </MobileBackground>
    <Content>
      <ContentCenter direction="column" directionMD="row"> 
        <Numbers>                  
          <h3 class="counter-aria-number">
            <span class="counter animated-first">114</span>
          </h3>
          <div class="counter-aria-divider"></div>
          <span class="counter-aria-title">Zrealizowane projekty</span>
        </Numbers>
        <Numbers>                  
          <h3 class="counter-aria-number">
            <span class="counter animated-first">16</span>
          </h3>
          <div class="counter-aria-divider"></div>
          <span class="counter-aria-title">Lat doświadczenia</span>
        </Numbers>
      </ContentCenter>
    </Content>
  </Row>
</Counter>
)
export default HomepageCounterSection