import React from "react";
import styled from "styled-components";
import { StaticImage } from "gatsby-plugin-image";
import Container from "../../partials/container";
import { color } from "../../../components/colors"
import HorizontalLine from "../../atoms/horizontal-line";
import Button from "../../atoms/button";
import Img from "gatsby-image"
//import { Parallax, Background } from "react-parallax"

const Top = styled.section`
  width: 100%;
  position: relative;
  min-height: 100vh;

  .custom-bg {
    .gatsby-image-wrapper {
      display: block !important;
      height: 100vh;
      width: 100%;
    }
  }
`

const Overlay = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  &.top-overlay {
    display: flex;
    flex-direction: column;
    padding-top: 70px;
    background-color: rgba(0, 0, 0, 0.4);

    .top-content {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }

  &.form-overlay {
    padding: 100px 0 60px;
    position: static;
  }
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 670px;
`;

const Title = styled.h1`
  color: ${color.white};
  font-size: 24px;
  line-height: 32px;
  text-transform: uppercase;
  margin-bottom: 24px;

  @media (min-width: 576px) {
    font-size: 32px;
    line-height: 40px;
  }

  @media (min-width: 769px) {
    font-size: 40px;
    line-height: 48px;
  }
`

const Subtitle = styled.span`
  color: ${color.primary};
  font-size: 18px;
  line-height: 26px;
  margin-top: 24px;
`

const TilesWrapper = styled.ul`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 10%;

  li {
    height: 250px;
    width: 400px;
    position: relative;

    .gatsby-image-wrapper {
      width: 100%;
      height: 100%;
    }

    .tile-overlay {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-end;
      background-color: rgba(0, 0, 0, 0.4);
      padding: 24px 56px;
      transition: background-color 0.3s ease;

      span {
        font-size: 24px;
        line-height: 32px;
        font-weight: 700;
        color: #fff;
        padding-bottom: 16px;
      }

      p {
        text-align: right;
        max-width: 60%;
        color: ${color.grey3};
      }
    }

    &:not(:first-child) {
      margin-top: 24px;
    }

    &:hover {
      .tile-overlay {
        background-color: ${color.secondary};
      }
    }
  }
`;

const HomepageTopSection = ({ data }) => (
<Top>
  <div className="custom-bg">
    <Img fixed={data.zdjecieGlowneObraz.localFile.childImageSharp.fluid} />
  </div>
<Overlay className="top-overlay">
  <Container className="top-content">
    <Content>
      <Title>Producent konstrukcji stalowych.</Title>
      {/* <HorizontalLine position="static" />
      <Subtitle>Wiodący na rynku europejskim producent specjalistycznych konstrukcji stalowych.</Subtitle> */}
      <Button 
        content={{
          text: "Sprawdź ofertę",
          url:
            "#oferta",
        }}
        margin="0 auto 0 0" 
      />
    </Content>
  </Container>
</Overlay>
{/* <TilesWrapper>
  <li>
    <StaticImage src="../../../images/home-3-project-1-632x330.jpg" />
    <Overlay className="tile-overlay">
      <span>Custom Forging</span>
      <p>I’m glad to offer you custom blacksmithing services of any complexity.</p>
    </Overlay>
  </li>
  <li>
    <StaticImage src="../../../images/home-3-project-2-632x330.jpg" />
    <Overlay className="tile-overlay">
      <span>Restoration & Repair</span>
      <p>Restoring any kind of ironwork is one of my numerous specializations.</p>
    </Overlay>
  </li>
</TilesWrapper> */}
</Top>
)
export default HomepageTopSection