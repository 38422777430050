import React from "react"
import styled from "styled-components"
import HorizontalLine from "../atoms/horizontal-line";

const Wrapper = styled.div`
  display: flex;
  padding-bottom: 24px;

  @media (min-width: 769px) {
    padding-bottom: 56px;
  }
`

const LineWrapper = styled.div`
    height: 30px;
    width: 0;
    position: relative;
`;

const SectionTitle = ({ title }) => (
  <Wrapper>
    <LineWrapper>
        <HorizontalLine width="100vw"/>
    </LineWrapper>
    <h2>{title}</h2>
  </Wrapper>
)

export default SectionTitle
