import React from "react"
import styled from "styled-components"
import Container from "../../partials/container"
import SectionTitle from "../../molecules/section-title";
import ContentCenter from "../../partials/content-center"
import Form from "../../organisms/form"

const Contact = styled.section`
padding: 48px 0;

@media (min-width: 769px) {
  padding: 104px 0;
}
`

const ContactSection = ({ className }) => (
  <Contact id="kontakt" className={className}>
    <Container>
      <SectionTitle title="Kontakt" />
      <Form />
    </Container>
  </Contact>
)

export default ContactSection
