import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { color } from "../../components/colors"
import PropTypes from "prop-types"

const Wrapper = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${props => (props.height ? props.height : "auto")};
  width: 100%;
  background-color: ${props =>
    props.theme === "primary40" ||
    props.theme === "primary48" ||
    props.theme === "primary56"
      ? color.secondary
      : color.secondary};
  //background-image: linear-gradient(90deg, ${color.secondary} 0%, ${color.third} 100%);
  border: 2px solid ${color.secondary};
  color: ${props =>
    props.theme === "primary40" ||
    props.theme === "primary48" ||
    props.theme === "primary56"
      ? color.black
      : color.black};
  font-size: 14px;
  line-height: 14px;
  font-weight: 700;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  position: relative;
  padding: 12px 22px;
  margin: ${props => (props.margin ? props.margin : "")};
  transition: background-color 0.3s ease, color 0.3s ease, border-color 0.3s ease;

  @media (min-width: 576px) {
    width: auto;
    white-space: ${props => (props.wrap ? "" : "nowrap")};
    max-width: ${props => props.width};
    margin: ${props => (props.marginSM ? props.marginSM : "")};
  }

  @media (min-width: 769px) {
    margin: ${props => (props.marginMD ? props.marginMD : "")};
  }

  @media (min-width: 992px) {
    margin: ${props => (props.marginLG ? props.marginLG : "")};
  }

  @media (min-width: 1200px) {
    margin: ${props => (props.marginXL ? props.marginXL : "")};
  }

  &:hover {
    //background-image: linear-gradient(90deg, #FFF 0%, #FFF 100%);
    background-color: ${props =>
      props.theme === "primary40" ||
      props.theme === "primary48" ||
      props.theme === "primary56"
        ? color.white
        : color.white};
    border-color: ${props =>
      props.theme === "primary40" ||
      props.theme === "primary48" ||
      props.theme === "primary56"
        ? color.white
        : color.white};
    color: ${props =>
      props.theme === "primary40" ||
      props.theme === "primary48" ||
      props.theme === "primary56"
        ? color.black
        : color.black};
  }
`

const Button = ({
  content,
  id,
  className,
  theme,
  height,
  width,
  arrow,
  arrow2,
  margin,
  marginSM,
  marginMD,
  marginLG,
  padding,
  wrap,
  onClick,
  target,
}) => (
  <Wrapper
    id={id}
    className={className}
    to={content.url ? content.url : content}
    height={height}
    width={width}
    arrow={arrow}
    arrow2={arrow2}
    theme={theme}
    margin={margin}
    marginSM={marginSM}
    marginMD={marginMD}
    marginLG={marginLG}
    padding={padding}
    wrap={wrap}
    onClick={onClick}
    target={target}
  >
    {content.text}
  </Wrapper>
)

export default Button

Button.propTypes = {
  width: PropTypes.string,
  target: PropTypes.string,
  rel: PropTypes.string,
  content: {
    text: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  },
}

Button.defaultProps = {
  rel: "noopener noreferrer nofollow",
  target: "_blank",
  width: "auto",
  content: {
    text: "Start Your Business with Us, Today!",
    url: "/",
  },
}
