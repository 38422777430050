import React from "react"
import styled from "styled-components"
import { color } from "../../components/colors"
import FormAcceptance from "../molecules/form-acceptance"
import FormButton from "../atoms/button"
import VisibilitySensor from "../../components/VisibilitySensor"

const Wrapper = styled.div`
  width: 100%;
  background-color: #1c1b1e;
  padding: 40px 15px;
  
  @media (min-width: 576px) {
    padding: 56px 32px;
  }
  
  @media (min-width: 769px) {
    padding: 104px 64px;
  }

  .from-label {
    p {
      line-height: 16px;
    }
  }

  label {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 52px;
    
    @media (min-width: 769px) {
      align-items: flex-end;
      padding: 0 15px;
    }

    @media (min-width: 992px) {
      flex-direction: row;
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    textarea:-webkit-autofill,
    textarea:-webkit-autofill:hover,
    textarea:-webkit-autofill:focus {
      -webkit-box-shadow: 0 0 0px 1000px ${color.white} inset;
    }
  }
`

const Flexbox = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: 769px) {
    flex-direction: row;
  }
`

const Flexbox2 = styled.div`
  display: flex;
  align-items: center;
`

const FormInput = styled.input`
  height: 52px;
  width: 100%;
  font-family: Roboto, sans-serif;
  background-color: transparent;
  border: 0;
  border-bottom: 1px solid ${color.white};
  border-radius: 0;
  padding: 5px;
  color: ${color.white};

  &::placeholder {
    color: ${color.grey3};
  }

  &:hover,
  &:focus {
    border-bottom: 1px solid ${color.secondary};
    outline: none;
  }

  @media (min-width: 992px) {
    max-width: 570px;
  }
`

const FormTextarea = styled.textarea`
  height: 160px;
  width: 100%;
  font-family: Roboto, sans-serif;
  background-color: transparent;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-bottom: 1px solid ${color.white};
  border-radius: 0;
  outline: none;
  padding: 5px;
  resize: none;
  color: ${color.white};

  &::placeholder {
    color: ${color.grey3};
  }

  &:hover,
  &:focus {
    border-color: ${color.secondary};
    outline: none;
  }
`

const SubmitButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  width: 100%;
  background-color: ${color.secondary};
  border: 2px solid ${color.secondary};
  color: ${color.black};
  font-size: 14px;
  line-height: 14px;
  font-weight: 700;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  position: relative;
  padding: 12px 22px;
  transition: background-color 0.3s ease, color 0.3s ease, border-color 0.3s ease;
  cursor: pointer;

  @media (min-width: 576px) {
    width: auto;
    white-space: nowrap;
  }

  &:hover {
    background-color: ${color.white};
    border-color: ${color.white};
    color: ${color.black};
  }
`

const Form = () => {
  return (
    <Wrapper>
      <form
        name="Contact"
        method="post"
        netlify-honeypot="bot-field"
        data-netlify="true"
      >
        <input type="hidden" name="bot-field" />
        <Flexbox>
          <VisibilitySensor partialVisibility once>
            {({ isVisible }) => (
              <label
                className={
                  isVisible ? "slideUp enter from-label" : "slideUp from-label"
                }
                htmlFor="name"
              >
                {/* <p>Nazwa</p> */}
                <FormInput type="name" name="name" placeholder="Imię" required />
              </label>
            )}
          </VisibilitySensor>
          <VisibilitySensor partialVisibility once>
            {({ isVisible }) => (
              <label
                className={
                  isVisible ? "slideUp enter from-label" : "slideUp from-label"
                }
                htmlFor="email"
              >
                {/* <p>Twój adres e-mail:</p> */}
                <FormInput type="email" name="email" placeholder="E-mail" required />
              </label>
            )}
          </VisibilitySensor>
          <VisibilitySensor partialVisibility once>
            {({ isVisible }) => (
              <label
                className={
                  isVisible ? "slideUp enter from-label" : "slideUp from-label"
                }
                htmlFor="topic"
              >
                {/* <p>Temat wiadomości:</p> */}
                <FormInput type="text" name="topic" placeholder="Temat wiadomości" />
              </label>
            )}
          </VisibilitySensor>
        </Flexbox>
        <VisibilitySensor partialVisibility once>
          {({ isVisible }) => (
            <label
              className={isVisible ? "slideUp enter" : "slideUp"}
              htmlFor="message"
              style={{ position: "relative" }}
            >
              <FormTextarea type="text" name="message" placeholder="Twoja wiadomość"/>
            </label>
          )}
        </VisibilitySensor>
        <FormAcceptance />
        <Flexbox2>
          <VisibilitySensor partialVisibility once>
            {({ isVisible }) => (
              <SubmitButton
                className={isVisible ? "slideUp enter" : "slideUp"}
                type="submit"
              >Wyślij wiadomość</SubmitButton>
            )}
          </VisibilitySensor>
        </Flexbox2>
      </form>
    </Wrapper>
  )
}

export default Form
