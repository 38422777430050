import * as React from "react"
import { Link } from "gatsby"
import { graphql } from "gatsby"
import { Parallax, Background } from "react-parallax"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Top from "../atomic/sections/homepage/top";
import About from "../atomic/sections/homepage/about";
import Services from "../atomic/sections/homepage/services";
import Gallery from "../atomic/sections/homepage/gallery";
import Counter from "../atomic/sections/homepage/counter";
import Contact from "../atomic/sections/homepage/contact"
import Footer from "../atomic/sections/homepage/footer";
import Button from "../atomic/atoms/button"
import Form from "../atomic/organisms/form"
import styled from "styled-components"
import ContentCenter from "../atomic/partials/content-center"
import ContentLeft from "../atomic/partials/content-left"
import LeftContentRightObject from "../atomic/partials/left-content-right-object"
import LeftObjectRightContent from "../atomic/partials/left-object-right-content"
import Container from "../atomic/partials/container"
import { color } from "../components/colors"
import GoogleMap from "../components/map"
//import Gallery from "../components/gallery"
import Arrow from "../images/arrow.inline.svg"

const Section = styled.section`
  position: relative;

  &.image-sction-1 {
    .react-parallax {
      height: 350px;
    }
  }

  &.about-sction {
    padding: 100px 0;

    p:first-child {
      font-size: 22px;
      line-height: 30px;
      margin-bottom: 40px;
    }

    p:not(:first-child) {
      font-size: 19px;
      line-height: 27px;
      margin-bottom: 24px;

      span {
        color: ${color.primary};
      }
    }
  }

  &.gallery-section {
    padding-top: 36px;

    span {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      max-width: 360px;
      padding: 8px 22px;
      border: 4px solid ${color.blueLight};
      margin-right: auto;
      font-size: 30px;
      line-height: 38px;
      text-transform: uppercase;
    }
  }

  &.form-section {
    .react-parallax {
      position: absolute !important;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }

    .form-overlay {
      h2,
      p {
        color: #fff;
      }

      h2 {
        font-size: 36px;
        line-height: 44px;
        margin-bottom: 18px;
      }

      p {
        font-size: 18px;
        line-height: 26px;
        margin-bottom: 0;
      }

      svg {
        display: none;
        transform: scale(-1, 1);
        height: 115px;
        position: relative;
        top: 32px;
        left: 160px;

        path {
          fill: #fff;
        }

        @media (min-width: 992px) {
          display: block;
        }
      }
    }
  }

  &.address-section {

    .gatsby-image-wrapper {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: 0 !important;
    }

    h2 {
      color: ${color.grey};
      font-size: 36px;
      line-height: 42px;

      @media (min-width: 992px) {
        text-align: right;
      }
    }

    ul {
      list-style-type: none;

      li {
        color: ${color.grey};
        font-size: 18px;
        line-height: 26px;
        margin-top: 17px;

        @media (min-width: 992px) {
          text-align: right;
        }
      }
    }

    svg {
      display: none;
      height: 115px;
      position: relative;
      top: -15px;

      path {
        fill: ${color.grey};
      }

      @media (min-width: 992px) {
        display: block;
      }
    }
  }
`

const IndexPage = ({ data }) => (
  <Layout logo={data.wpPage.ustawienia}>
    <Seo title={data.wpPage.ustawienia.ustawieniaTytulStrony} />
    <Top data={data.wpPage.zdjecieGlowne} />
    <About data={data.wpPage.oMnie} />
    <Services data={data.wpPage.oferta} />
    <Gallery data={data.wpPage.galeria} />
    <Counter data={data.wpPage.galeria} />
    <Contact />
    <Footer />
  </Layout>
)

export default IndexPage

export const pageQuery = graphql`
  query WordpressAktyPrawne {
    wpPage(id: { eq: "cG9zdDoy" }) {
      title
      ustawienia {
        ustawieniaTytulStrony
        ustawieniaOpisStrony
        ustawieniaLogoStrony {
          sourceUrl
          localFile {
            childImageSharp {
              fixed(width: 120) {
                src
              }
            }
          }
        }
        ustawieniaKolorPrzewodni
        ustawieniaKolorDodatkowy
      }
      zdjecieGlowne {
        zdjecieGlowneObraz {
          sourceUrl
          localFile {
            childImageSharp {
              fluid(maxWidth: 1920) {
                src
              }
            }
          }
        }
      }
      oMnie {
        oMnieTytul
        oMnieOpisLewaStrona
        oMnieOpisPrawaStrona
      }
      oferta {
        ofertaRodzajeUslug {
          ofertaRodzajeUslugNazwa
          ofertaRodzajeUslugOpis
          ofertaRodzajeUslugZdjecie {
            localFile {
              childImageSharp {
                fluid(maxWidth: 500) {
                  src
                }
              }
            }
            sourceUrl
          }
        }
        ofertaTytul
      }
      galeria {
        galeria {
          galeriaNazwa
          galeriaKategorie {
            galeriaKategoriaZdjecia {
              sourceUrl
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1920) {
                    src
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
