import React from "react"
import styled from "styled-components"
import { color } from "../../../components/colors"
import { FlexBox } from "../../../components/flexbox"
import Container from "../../partials/container";
import Button from "../../atoms/button";

const Wrapper = styled.footer`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 56px;
`

const ListBox = styled.div`
  @media (min-width: 769px) {
    border-left: 4px solid ${color.secondary};
    padding-left: 35px;
  }

  &:not(:nth-last-child(1)) {
    padding-bottom: 25px;
  }

  @media (min-width: 992px) {
    &:not(:nth-last-child(1)) {
      padding-bottom: 0;
    }
  }
`

const List = styled.div`
  list-style: none;
  padding: 0;
  margin: 0;
`

const ListItem = styled.div`
  .tel, .mail {
    text-decoration: none;
    color: ${color.secondary};
  }
`

const Footer = () => {
  return (
    <Wrapper>
      <Container>
        <FlexBox
          direction={"column"}
          directionLG={"row"}
          justify={"space-between"}
        >
          <ListBox>
            <List>
              <ListItem>
                <p>P.A.K Konstrukcje</p>
              </ListItem>
              <ListItem>
                <p>NIP 8581657762</p>
              </ListItem>
            </List>
          </ListBox>
          <ListBox>
            <List>
              <ListItem>
                <p>ul. Gryfińska 22</p>
              </ListItem>
              <ListItem>
                <p>74-100 Czepino</p>
              </ListItem>
            </List>
          </ListBox>
          <ListBox>
            <List>
              <ListItem>
                <p>
                  tel.: <a className="tel" href="tel:48693975699">+48 789 422 711</a>
                </p>
              </ListItem>
              <ListItem>
                <p>
                  e-mail:{" "}
                  <a className="mail" href="mailto:radca.annamilcarz@gmail.com<">
                    pak.konstrukcje@o2.pl
                  </a>
                </p>
              </ListItem>
            </List>
          </ListBox>
          <ListBox>
            <List>
              <ListItem>
                <Button
                  theme="primary40"
                  content={{
                    text: "Otwórz mapę",
                    url:
                      "https://goo.gl/maps/1dYYNjgr3ycUZ9mJ8",
                  }}
                  margin="0 auto 0 0"
                />
              </ListItem>
            </List>
          </ListBox>
        </FlexBox>
      </Container>
    </Wrapper>
  )
}

export default Footer
