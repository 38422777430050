import React from "react";
import styled from "styled-components";
import { StaticImage } from "gatsby-plugin-image";
import Container from "../../partials/container";
import { color } from "../../../components/colors"
import SectionTitle from "../../molecules/section-title";
import ServiceImages from "../../organisms/service-images";
import Button from "../../atoms/button"
import parse from "html-react-parser"
//import { Parallax, Background } from "react-parallax"

const About = styled.section`
  width: 100%;
  position: relative;
  padding: 48px 0 0;

  @media (min-width: 769px) {
    padding: 104px 0 0;
  }
`

const Content = styled.div`
  display: flex;  
  flex-direction: column;
  width: 100%;

  @media (min-width: 769px) {
    flex-direction: row;
    flex-wrap: wrap;
  }

  .content-left,
  .content-right {
    display: flex;
    flex-direction: column;
  }

  .content-left {
    justify-content: space-between;
    width: 100%;
    margin-bottom: 16px;

    @media (min-width: 576px) {
      padding-bottom: 24px;
    }
    
    @media (min-width: 769px) {
      padding-right: 64px;
      margin-bottom: 32px;
    }

    &--relative {
      @media (min-width: 769px) {
        position: relative;
        top: -24px;
      }
    }
    
    @media (min-width: 769px) {
      width: 54%;
      margin-bottom: 0;
    }
    
    p {
      font-size: 20px;
      line-height: 28px;
      font-weight: 700;

      @media (min-width: 576px) {
        font-size: 24px;
        line-height: 32px;
      }

      @media (min-width: 769px) {
        font-size: 28px;
        line-height: 36px;
      }
    }
  }
  
  .content-right {
    width: 100%;
    
    @media (min-width: 769px) {
      width: 46%;
    }

    h2 {
      font-size: 16px;
      line-height: 24px;
      padding-bottom: 16px;
      
      @media (min-width: 576px) {
        font-size: 20px;
        line-height: 28px;
        padding-bottom: 24px;
      }
      
      @media (min-width: 769px) {
        font-size: 24px;
        line-height: 32px;
        padding-bottom: 32px;
      }
    }

    p {
      font-size: 16px;
      line-height: 24px;
    }
  }
`

const HomepageAboutSection = ({ data }) => (
<About id="o-mnie">
  <Container>
    <SectionTitle title={data.oMnieTytul} />
    <Content>
      <div className="content-left">
        {parse(data.oMnieOpisLewaStrona)}
      </div>
      <div className="content-right">
        {parse(data.oMnieOpisPrawaStrona)}
      </div>
      <div className="content-left content-left--relative">
      <Button
          theme="primary40"
          content={{
            text: "Nasze realizacje",
            url:
              "#realizacje",
          }}
          margin="24px auto 0 0"
        />
      </div>
    </Content>
  </Container>
</About>
)
export default HomepageAboutSection