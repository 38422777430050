import styled from "styled-components";

export const FlexBox = styled.div`
  display: flex;
  flex-direction: ${props => (props.direction ? props.direction : 'row')};
  justify-content: ${props => (props.justify ? props.justify : 'flex-start')};
  align-items: ${props => (props.align ? props.align : 'flex-start')};
  padding: ${props => margin(props.p ? props.p : '0')};

  @media (min-width: 992px) {
    flex-direction: ${props => (props.directionLG ? props.directionLG : props.direction)};
    justify-content: ${props => (props.justifyLG ? props.justifyLG : props.justify)};
    align-items: ${props => (props.alignLG ? props.alignLG : props.align)};
    padding: ${props => margin(props.pLG ? props.pLG : props.p)};
  }
`

const margin = (margin) => {
    if (typeof margin === 'string') {
      return margin;
    } else if (typeof margin === 'number') {
      if (margin === 1) {
        return '3px'
      } else if (margin === 2) {
        return '8px'
      } else if (margin === 3) {
        return '15px'
      } else if (margin === 4) {
        return '30px'
      } else if (margin === 5) {
        return '48px'
      } else if (margin === 6) {
        return '72px'
      } else {
        return '0'
      }
    } else {
      return '0'
    }
  }