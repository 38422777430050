import React from "react";
import styled from "styled-components";
import { StaticImage } from "gatsby-plugin-image";
import Container from "../../partials/container";
import { color } from "../../../components/colors"
import SectionTitle from "../../molecules/section-title";
import ServiceImages from "../../organisms/service-images";
//import { Parallax, Background } from "react-parallax"

const Services = styled.section`
  width: 100%;
  position: relative;
  padding: 48px 0 0;

  @media (min-width: 769px) {
    padding: 104px 0 0;
  }
`

const HomepageServicesSection = ({ data }) => (
<Services id="oferta">
  <Container>
    <SectionTitle title={data.ofertaTytul} />
  </Container>
  <ServiceImages data={data.ofertaRodzajeUslug}/>
</Services>
)
export default HomepageServicesSection